<template>
  <div>
    <div
      v-if="pageReady"
      class="container d-flex flex-column container align-items-start mb-5 p-4"
    >
      <!-- selected services section -->
      <InfoBox :title="`Selected Service Requirement(s)`">
        <div class="container mt-4">
          <InfoBoxAlternatingContent
            v-if="serviceAndFeesInfo"
            :content="serviceAndFeesInfo"
          />
        </div>
      </InfoBox>

      <form
        id="patientDetailsSection"
        ref="patientDetailsSection"
        class="bg-white p-5 w-100"
      >
        <fieldset>
          <h1 class="mb-5 text-dark">
            <strong>Patient Details</strong>
          </h1>

          <div class="py-4 row justify-content-between">
            <div class="col-5 pe-2">
              <FormInput
                v-model:modelValue="patient.occupation_during_injury"
                :error="patientErrors.occupation_during_injury"
                :label="`patient.occupation`"
              >
                <label class="form-check-label" for="occupation">
                  Occupation at the time of the Injury
                </label>
              </FormInput>
            </div>

            <div class="col-6">
              <p class="label text-dark">Previous Occupation Status</p>

              <BaseRadioGroup
                v-model="patient.previous_occupation_status"
                :error="patientErrors.previous_occupation_status"
                :options="occupationOptions"
              />
            </div>
          </div>

          <div class="py-4 row justify-content-between">
            <div class="col-5 pe-2">
              <BaseSelectInput
                v-model="patient.current_employment_status"
                :label="`Current Employment Status`"
                :options="employmentStatusOptions"
              />
            </div>

            <div class="col-6">
              <label class="form-check-label text-dark">Last day of work</label>
              <datepicker
                v-model="patient.last_day_of_work"
                :model-value="convertToDateFormat(patient.last_day_of_work)"
                autocomplete="off"
                input-format="dd MMMM yyyy"
                class="datepicker-input-box has-validation"
                :class="patientErrors.last_day_of_work ? 'border-danger' : ''"
                style="
                  --vdp-selected-bg-color: #32c3d6;
                  --vdp-hover-bg-color: #32c3d6;
                "
              />
              <div v-if="patientErrors.last_day_of_work" class="error-warning">
                {{ patientErrors.date_of_birth?.[0] }}
              </div>
            </div>
          </div>

          <div class="row my-4">
            <div class="col-6">
              <span class="label text-dark">Social Status</span>
              <BaseRadioGroup
                v-model="patient.social_status"
                :label="`Social Status`"
                :error="patientErrors.social_status"
                :options="socialStatusOptions"
              />
            </div>

            <div class="col-6">
              <FormInput
                v-model:modelValue="patient.current_working_time"
                :placeholder="`...35hours a week ...7hours a day`"
                :error="patientErrors.current_working_time"
                :label="`occupation`"
              >
                <label class="form-check-label" for="occupation">
                  Current Working Time
                </label>
              </FormInput>
            </div>
          </div>

          <div class="my-5">
            <label class="form-check-label" for="level_of_education">
              Level of Education
            </label>
            <textarea
              id="level_of_education"
              v-model="patient.level_of_education"
              placeholder="please explain as detail as possible..."
              class="form-control"
              rows="3"
            />
          </div>

          <div class="my-5">
            <FormInput
              v-model:modelValue="patient.language_spoken"
              :error="patientErrors.language_spoken"
              :label="`language_spoken`"
            >
              <label class="form-label" for="language_spoken">
                Language Spoken
              </label>
            </FormInput>
          </div>

          <div class="py-4 row">
            <div class="col-6">
              <label class="form-check-label">Past medical history</label>

              <BaseCheckboxGroup
                v-model="patient.past_medical_history"
                :error="patientErrors.past_medical_history"
                :options="medicalConditions"
                :inline="false"
                :label="`Past medical history`"
              />

              <div v-if="patient.past_medical_history.includes(`other`)">
                <label class="form-label">Other illness:</label>
                <input
                  v-model="otherPastMedicalHistory"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-6">
              <BaseSelectInput
                v-model="patient.hand_preference"
                :error="patientErrors.hand_preference"
                :label="`Left or Right Handed`"
                :options="handednessOptions"
              />
            </div>
          </div>

          <div class="my-5">
            <label class="form-check-label" for="current_symptoms">
              Current symptom(s)
            </label>
            <textarea
              id="current_symptom"
              v-model="patient.current_symptom"
              placeholder="..."
              class="form-control"
              rows="5"
            />
          </div>

          <div class="my-5">
            <label class="form-check-label" for="current_treatments">
              Current treatments
            </label>
            <textarea
              id="current_treatments"
              v-model="patient.current_treatment"
              placeholder="please explain as detail as possible..."
              class="form-control"
              rows="3"
            />
          </div>
          <div class="my-5">
            <label class="form-check-label" for="current_medications">
              Current medications
            </label>
            <textarea
              id="current_medications"
              v-model="patient.current_medication"
              placeholder="please explain as detail as possible..."
              class="form-control"
              rows="3"
              :class="{ 'is-invalid': patientErrors.current_medication }"
            />
            <div class="invalid-feedback">
              {{ patientErrors.current_medication?.[0] }}
            </div>
          </div>

          <div class="d-grid gap-2 col-4 mx-auto">
            <button
              type="button"
              class="btn btn-primary rounded-pill text-white my-3"
              @click="submit"
            >
              <strong>Save</strong>
            </button>
          </div>
        </fieldset>
      </form>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue'
import BaseSelectInput from '@/components/Base/BaseSelectInput.vue'
import FormInput from '@/components/TPD/Components/FormInput.vue'
import Datepicker from 'vue3-datepicker'

import InfoBox from '@/components/TPD/Widgets/InfoBox.vue'
import InfoBoxAlternatingContent from '@/components/TPD/Widgets/InfoBoxAlternatingContent.vue'

import { getLastDayOfYear, convertDateToAuFormat } from '@/utils/generics.js'

export default {
  name: 'NurseForm',
  components: {
    BaseRadioGroup,
    BaseCheckboxGroup,
    Datepicker,
    FormInput,
    InfoBox,
    InfoBoxAlternatingContent,
    BaseSelectInput,
  },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:readyNext', 'update:toastText'],

  data() {
    return {
      medicalRecordID: null,

      intermittent_frequency: [
        {
          value: 'a couple times',
        },
        {
          value: 'a few times',
        },
      ],
      duration_of_frequency: [
        {
          value: 'a day',
        },
        {
          value: 'a week',
        },
        {
          value: 'a month',
        },
        {
          value: 'a year',
        },
      ],

      serviceAndFeesInfo: [],

      patient: {
        past_medical_history: [],
        current_symptoms: '',
      },
      patientErrors: {},
      otherPastMedicalHistory: '',

      employmentStatusOptions: [
        {
          value: 'UE',
          label: 'Not working',
        },
        {
          value: 'EM',
          label: 'Working',
        },
      ],

      handednessOptions: [
        {
          value: 'R',
          label: 'Right Handed',
        },
        {
          value: 'B',
          label: 'Ambidextrous',
        },
        {
          value: 'L',
          label: 'Left Handed',
        },
      ],

      // where is the "input" model?

      medicalConditions: [
        'diabetes',
        'arthritis',
        'cancer',
        'heart disease',
        'high blood pressure',
        'epilepsy',
        'thyroid disease',
        'other',
      ],

      occupationOptions: [
        {
          value: 'FT',
          label: 'Full-Time',
        },
        {
          value: 'PT',
          label: 'Part-Time',
        },
        {
          value: 'UE',
          label: 'Unemployed',
        },
      ],
      socialStatusOptions: [
        {
          value: 'S',
          label: 'Single',
        },
        {
          value: 'M',
          label: 'Married',
        },
        {
          value: 'D',
          label: 'Divorced',
        },
        {
          value: 'W',
          label: 'Widowed',
        },
      ],

      pageReady: false,
    }
  },

  computed: {
    currentYear() {
      return getLastDayOfYear()
    },
  },

  mounted() {
    if (this.$store.state.TPDAssessment) {
      //map patient details
      this.patient = this.$store.state.TPDAssessment.patient_detail

      //if user added Other past medical history, update to list

      //filter and find the inserted other value
      let initialOther = this.patient.past_medical_history.filter(
        (e) => !this.medicalConditions.includes(e)
      )

      // Append new values only when it exists
      if (initialOther) {
        for (let index in initialOther) {
          // Push new value before 'other' option
          this.medicalConditions.splice(
            this.medicalConditions.length - 1,
            0,
            initialOther[index]
          )
        }
      }

      //check symptom  data, if empty then start with some defaults

      //update service and fees display
      this.$store.state.TPDAssessment.referral.service_requirement.ime_report
        ? this.serviceAndFeesInfo.push('Additional IME report')
        : ''
      this.$store.state.TPDAssessment.referral.service_requirement.mas_report
        ? this.serviceAndFeesInfo.push('Additional MAS report')
        : ''
      let found =
        this.$store.state.TPDAssessment.referral.service_requirement_choices.find(
          (x) =>
            x.service_type ===
            this.$store.state.TPDAssessment.referral.service_requirement
              .service_type
        )

      if (found) {
        this.serviceAndFeesInfo = this.serviceAndFeesInfo.concat(
          found.service_display_name.split(/[&,]/)
        )
      }
    }
    this.pageReady = true
  },

  methods: {
    async submit() {
      let obj = {
        ...this.patient,
      }
      //format date
      obj.last_day_of_work = this.formatPostDate(obj.last_day_of_work)

      //check for own addition of past medical history into array
      if (this.otherPastMedicalHistory) {
        let foundOther = obj.past_medical_history.findIndex(
          (e) => e === 'other'
        )
        //remove the selection of 'other'
        obj.past_medical_history.splice(foundOther, 1)

        obj.past_medical_history.push(this.otherPastMedicalHistory)
      }

      await this.updatePatientDetails(obj)
    },

    async updatePatientDetails(obj) {
      await this.$store
        .dispatch('putTPDPatientDetails', {
          id: this.$route.params.id,
          payload: obj,
        })
        .then((response) => {
          this.patient = response.data
          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: 'Patient details updated successfully!',
          })
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            this.patientErrors = error.response.data
            this.$refs.patientDetailsSection.scrollIntoView()

            // Trigger toast text
            this.$root.toasts.push({
              status: 'warning',
              content: 'Incorrect patient details found.',
            })
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            // Trigger toast text
            this.$root.toasts.push({
              status: 'danger',
              content: `Error: ${error.message}`,
            })
          }
        })
    },

    formatPostDate(date) {
      if (date) {
        return convertDateToAuFormat(date)
      } else return null
    },

    convertToDateFormat(date) {
      if (date) {
        const result = new Date(date)

        return result
      } else return null
    },
  },
}
</script>

<style lang="scss">
.vue-form {
  font-size: 16px;

  padding: 15px 30px;
  border-radius: 4px;
  margin: 50px auto;

  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);

  h1 {
    font-size: 24px;
  }
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}

.vue-form h4,
.vue-form .label {
  color: #94aab0;
  margin-bottom: 10px;
}
.vue-form .label {
  display: block;
}

.vue-form input:focus,
.vue-form textarea:focus,
.vue-form select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}

input::placeholder {
  color: gray;
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
}
</style>
